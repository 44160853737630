import axios from 'src/_services/axios-configs'

const state = {
  labels: [],
  listLoading: false
}

const getters = {
  labels: state => state.labels,
  listLoading: state => state.listLoading
}

const mutations = {
  SET_LABELS(state, labels) {
    state.labels = labels
  },
  SET_LIST_LOADING(state, loading) {
    state.listLoading = loading
  }
}

const actions = {
  async getLabels({ commit }) {
    try {
      commit('SET_LIST_LOADING', true)

      const { data } = await axios.get('user-label')

      commit('SET_LABELS', data)
      return data
    } catch (error) {
      throw new Error(error)
    } finally {
      commit('SET_LIST_LOADING', false)
    }
  },
  async createLabel(_, { label }) {
    return new Promise((resolve, reject) => {
      axios
        .post('user-label', { label })
        .then(({ data }) => {
          resolve(data.success)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async editLabel(_, { id, label }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`user-label/${id}`, { label })
        .then(({ data }) => {
          resolve(data.success)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async deleteLabel(_, id) {
    try {
      const { data } = await axios.delete(`user-label/${id}`)

      return data.success
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
