import { parsePhoneNumberFromString, parsePhoneNumber, isValidPhoneNumber, Metadata } from 'libphonenumber-js'
import countries from '../constant/countries'

const metadata = new Metadata()

function isUSPhoneNumber(number) {
  try {
    if (!number) return ''
    if (number.length === 10) {
      number = '1' + number
    }
    const phoneNumber = parsePhoneNumberFromString('+' + number, 'US')
    return phoneNumber ? phoneNumber.country === 'US' && phoneNumber.isValid() : false
  } catch (error) {
    return false
  }
}

function beautifyPhone(number) {
  try {
    if (!number) return ''
    if (number.length === 10) {
      number = '1' + number
    }
    const beautified = parsePhoneNumber('+' + number)
    return beautified.formatNational()
  } catch (error) {
    return number
  }
}

function formatPhone(number) {
  if (!number) return ''
  if (isNaN(+number)) return number
  number = parseInt(number).toString()
  if (isUSPhoneNumber(number)) {
    return beautifyPhone(number)
  }
  const _number = `+${number}`
  if (isValidPhoneNumber(_number)) {
    return _number
  }
  return number
}

function getCountryIsoBy(number) {
  if (!number) return {}
  const first4 = `+${number.slice(0, 4)}`
  const minLength = []
  const maxLength = []
  countries
    .filter(item => first4.includes(item.dial_code))
    .forEach(item => {
      metadata.selectNumberingPlan(item.code)
      const possibleLengths = metadata.numberingPlan.possibleLengths()
      minLength.push(...possibleLengths)
      maxLength.push(...possibleLengths.map(item => item + (metadata.numberingPlan?.metadata[0]?.length ?? 0)))
    })
  if (minLength.length === 0) return {}
  return {
    min: Math.min(...minLength),
    max: Math.max(...maxLength)
  }
}

export {
  formatPhone,
  getCountryIsoBy
}
