import { io } from 'socket.io-client'
import CONFIG from '../_services/config'

const BASE_URL = 'wss://' + CONFIG.apiUrl
const APP_NAME = 'FREEFONE'

let socket = null

const initSocket = (token) => {
  socket = io(BASE_URL, {
    // transports: ['websocket', 'polling'],
    extraHeaders: {
      Authorization: `Bearer ${token}`
    }
  })
  socket.once('connect', () => {
    console.log(`[${APP_NAME}] Socket Connected`)
  })
  socket.once('disconnect', () => {
    console.log(`[${APP_NAME}] Socket Disconnected`)
  })
}
const closeSocket = () => {
  if (socket) {
    socket.close()
  }
}

export {
  initSocket, socket,
  closeSocket
}
