import axios from 'axios'
import config from './config'
import authStore from '../store/modules/auth'
import router from 'src/router'

const multipartDataURLs = [
  '/upload/user/image',
  '/recording',
  '/voicemail_greeting'
]

const errorStatus = [401, 403]

const instance = axios.create({
  baseURL: config.protocol + '//' + config.apiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

instance.interceptors.request
  .use(
    (config) => {
      const user = authStore.state.currentUser
      const token = user ? user.token : null

      // console.log(config, 'config')
      if (token) {
        config.headers.Authorization = 'Bearer' + ' ' + token

        if (multipartDataURLs.includes(config.url)) {
          config.headers.Accept = '*/*'
          config.headers['Content-Type'] = 'multipart/form-data'
        }
      }

      return config
    },
    (err) => {
      return Promise.reject(err)
    })

instance.interceptors.response
  .use(
    (res) => {
      return res
    },
    (err) => {
      const status = err.response.status
      if (errorStatus.includes(status) && router.currentRoute.path !== '/login' && router.currentRoute.name !== 'Confirmation') {
        router.push({ path: '/login' })
        authStore.actions.logout()
      }

      return Promise.reject(err)
    })

export default instance
