import axios from 'src/_services/axios-configs'
import router from '../../router'

const state = {
  currentUser: JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')) : null,
  currentCompany: JSON.parse(localStorage.getItem('currentCompany')) ? JSON.parse(localStorage.getItem('currentCompany')) : null,
  userEmail: '',
  userPassword: '',
  userNumber: null
}

const getters = {
  currentUser (state) {
    return state.currentUser
  },
  currentCompany (state) {
    return state.currentCompany
  },
  getEmail (state) {
    return state.userEmail
  },
  getPassword (state) {
    return state.userPassword
  },
  getNumber (state) {
    return state.userNumber
  }
}

const mutations = {
  setCurrentUser (state, user) {
    state.currentUser = user
  },
  setEmail (state, email) {
    state.userEmail = email
  },
  setPassword (state, password) {
    state.userPassword = password
  },
  setNumber (state, number) {
    state.userNumber = number
  }
}

const actions = {
  setCurrentUser ({ commit }, user) {
    commit('setCurrentUser', user)
  },
  setEmail ({ commit }, email) {
    commit('setEmail', email)
  },
  setPassword ({ commit }, password) {
    commit('setPassword', password)
  },
  setNumber ({ commit }, number) {
    commit('setNumber', number)
  },
  login ({ dispatch }, list) {
    return new Promise((resolve, reject) => {
      axios.post('/user/login', list)
        .then(res => {
          dispatch('setCurrentUser', res.data)

          localStorage.setItem('currentUser', JSON.stringify(res.data))
          localStorage.setItem('sipAccess', JSON.stringify({
            sip_username: res.data.sip_username
            // password: list.password
          }))

          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  registration (_, list) {
    return new Promise((resolve, reject) => {
      axios.post('/user/register', list)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  reSendConfirmCode (_, uuid) {
    return new Promise((resolve, reject) => {
      axios.post(`/user/${uuid}/resend_code`)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  confirmEmail (_, token) {
    return new Promise((resolve, reject) => {
      axios.get(`/user/confirm_email/${token}`)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  resetPassword (_, data) {
    return new Promise((resolve, reject) => {
      axios.post('/user/change_password', data)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  changePassword (_, data) {
    return new Promise((resolve, reject) => {
      axios.post('/user/submit_new_password', data)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  logout ({ dispatch }, redirect = true) {
    try {
      // remove USER and COMPANY from local storage to log user out
      localStorage.removeItem('currentUser')
      localStorage.removeItem('sipAccess')
      localStorage.removeItem('userProfile')

      dispatch('setCurrentUser', null)
      if (!redirect) return
      router.push('/login')
    } catch (err) {
      throw new Error(err)
    }
  },
  getSipToken () {
    return new Promise((resolve, reject) => {
      axios.post('/user/sip_token')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getSocialNetworks (_, type) {
    return new Promise((resolve, reject) => {
      axios.get('/user/social-networks/get_url', { params: { type } })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  confirmSocialCode ({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios.post('user/social-networks/confirm_code', data)
        .then(res => {
          dispatch('setCurrentUser', res.data)

          localStorage.setItem('currentUser', JSON.stringify(res.data))
          localStorage.setItem('sipAccess', JSON.stringify({
            sip_username: res.data.sip_username
            // password: list.password
          }))

          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
