import axios from 'src/_services/axios-configs'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getNumbers(_) {
    return new Promise((resolve, reject) => {
      axios
        .get('/number/user/numbers')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getLinkedNumber(_) {
    return new Promise((resolve, reject) => {
      axios
        .get('/user/linked-number')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  sendLinkedNumber(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/user/linked-number', data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  deleteLinkedNumber(_, number) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/user/linked-number/${number}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  verifyLinkedNumber(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/user/linked-number/verify_code', data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  changeCallForwording(_, { trigger, uuid }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user/linked-number/enable_call_forward/${trigger}/${uuid}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  releaseNumber(_) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/number/release')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getBlockedNumbers(_, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get('/blocking', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  blockNumber(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/blocking', data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  unblockNumber(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/blocking/${data}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getSpamNumbers(_) {
    return new Promise((resolve, reject) => {
      axios
        .get('/spam/user')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  isSpamNumber(_, number) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/spam/user/${number}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  saveSpamNumber(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/spam/user/save', data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  deleteSpamNumber(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/spam/user/delete/${data}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  sendSMS(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/sms/send', data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  assignNumber(_, number) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/number/assign/${number}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getAvailableNumbers(_, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post('/number/get_available_numbers', {}, { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getAvailableNpa(_, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get('/number/free_available_city', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  markSpamNumber(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/logging/dialpad/call/${data.dir}/${data.uuid}/is_spam/${!data.is_spam}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  deleteNumber(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/logging/dialpad/call/${data.direction ?? 'in'}/${data.uuid}/is_purge/true`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  deleteChatByNumber(_, number) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/logging/sms/chat/${number}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  archiveNumber(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/logging/dialpad/${data._type}/${data.direction ?? 'in'}/${data.uuid}/archivedAt/${data.trigger}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  }
}

export default { namespaced: true, state, getters, mutations, actions }
