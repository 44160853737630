import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import numbers from './modules/numbers'
import sip from './modules/sip'
import calls from './modules/calls'
import voicemails from './modules/voicemails'
import labels from './modules/labels'
import contacts from './modules/contacts'
import messages from './modules/messages'
import admin from './modules/admin'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showPeople: false,
    toggleSidebar: false,
    alertStatus: null,
    messageTo: false,
    menuNumber: '',
    sendMessageTo: '',
    changedMenu: false
  },
  mutations: {
    showPeopleFunction(state, newVal) {
      state.showPeople = newVal
    },
    changeMenu(state) {
      state.changedMenu = !state.changedMenu
    },
    // eslint-disable-next-line no-unused-vars
    changeToggleSidebarFunction(state, newVal) {
      state.toggleSidebar = newVal !== undefined ? newVal : !state.toggleSidebar
      // console.log(newVal, state.toggleSidebar, 'changeToggleSidebarFunction')
    },
    sendAlert(state, payload) {
      state.alertStatus = payload
    },
    changeMessageTo(state, to) {
      state.messageTo = to
    },
    sendMessageOnCall(state, number) {
      state.sendMessageTo = number
    },
    actionMenuNumber(state, number) {
      state.menuNumber = number
    }
  },
  actions: {
    showPeopleAction({ state, commit }, param) {
      commit('showPeopleFunction', param)
    },
    showAlert({ commit }, { message, error, callback }) {
      commit('sendAlert', {
        img: message ? 'svg/verify-success.svg' : 'png/error.png',
        message,
        error,
        buttonText: 'OK',
        callback
      })
    },
    successAlert({ commit }, message) {
      commit('sendAlert', {
        img: 'svg/verify-success.svg',
        message,
        buttonText: 'OK'
      })
    },
    errorAlert({ commit }, message) {
      commit('sendAlert', {
        img: 'png/error.png',
        message,
        buttonText: 'OK'
      })
    },
    closeAlert({ commit }) {
      commit('sendAlert', null)
    }
  },
  getters: {
    getMenu(state) {
      return state.changedMenu
    },
    GetToggleSidebar(state) {
      return state.toggleSidebar
    },
    getAlertStatus(state) {
      return state.alertStatus
    },
    getMessageTo(state) {
      return state.messageTo
    },
    getMessageOnCall(state, number) {
      console.log(number, '666666')
      return number.sendMessageTo
    },
    getActionMenuNumber(state) {
      return state.menuNumber
    }
  },
  modules: {
    auth,
    numbers,
    user,
    sip,
    calls,
    voicemails,
    labels,
    contacts,
    messages,
    admin
  }
})
