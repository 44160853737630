const keyName = 'voicemail-uuid'
const setVoicemailStorage = (phoneNumber) => {
  sessionStorage.setItem(keyName, phoneNumber)
}
const getVoicemailStorage = () => {
  return sessionStorage.getItem(keyName)
}
const removeVoicemailStorage = () => {
  sessionStorage.removeItem(keyName)
}

export {
  setVoicemailStorage,
  getVoicemailStorage,
  removeVoicemailStorage
}
