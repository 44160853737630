<template>
  <v-app>
    <v-main :class="{ 'settings-page': $route.name === 'Settings' }" style="position: relative">
      <router-view />
      <CallBlock v-if="!isAdmin && isExternalRoute && showCallBlock" :showDialPad="false" style="position: fixed; bottom: 0; right: 0; height: calc(100vh - 72px)" />
    </v-main>
    <audio style="display: none" id="remoteAudio" ref="remoteAudio" controls>
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>
<!--    <audio style="display: none" id="localAudio" ref="localAudio" controls>-->
<!--      <p>Your browser doesn't support HTML5 audio.</p>-->
<!--    </audio>-->
  </v-app>
</template>

<script>
import jwtDecode from 'jwt-decode'
import {
  addSession, beep,
  callDecline, connectSIP,
  disconnectSIP, getNumberOfSession, getSessionById,
  getSessionType, INCOMING_REQUEST_NAME,
  isOutboundCall, OUTGOING_REQUEST_NAME, removeSessionFromCollector, SIP_DOMAIN, SIP_PORT, smCollector,
  unregisterSIP,
  updateExtraHeaders
} from './utilities/sessionManager'
import CallBlock from './components/sip/CallBlock.vue'
import { playAudioRing, stopAudioRing } from './utilities/audio-sounds'
import { initSocket, closeSocket, socket } from './utilities/socketIO'
import { notificationInit, showNotification } from './utilities/web-notifications'
import { mapActions } from 'vuex'

const baseRoutes = [
  'Base',
  'Calls',
  'Messages',
  'Voicemails',
  'Voicemail',
  'Archive',
  'Spam',
  'Upgrade',
  'Labels'
]
const externalRoutes = [
  'Purchase',
  'Settings',
  'AddRule',
  'EditRule',
  'ManageRule',
  'CallingRates',
  'BillingHistory',
  'NewMessage'
]

export default {
  name: 'App',
  components: { CallBlock },
  data: () => ({
    sipAccess: {},
    sip: {
      connected: false,
      registered: false,
      loading: false,
      logger: false
    },
    sipToken: null,
    sipTokenEndTime: 0,
    sipTokenTimeoutId: null,
    flagToUpdateSipToken: false,
    sipInbound: {
      show: false
    }
  }),
  async beforeDestroy() {
    await this.disconnect()
  },
  watch: {
    routerName () {
      this.sipMiddleware()
    }
  },
  computed: {
    user () {
      return this.$store.state.user.userProfile
    },
    isAdmin () {
      return this.user && this.user.is_admin
    },
    profileNumber() {
      return this.user?.number
    },
    currentUser() {
      return this.$store.state.auth.currentUser
    },
    routerName () {
      return this.$route.name
    },
    isBaseRoute () {
      return baseRoutes.includes(this.$route.name)
    },
    isExternalRoute () {
      return externalRoutes.includes(this.$route.name)
    },
    isAuthRoutes () {
      return this.isBaseRoute || this.isExternalRoute
    },
    sipUserName () {
      return this.sipAccess?.sip_username
    },
    getOutboundCall () {
      return this.$store.state.sip.calls.find(call => call.type === OUTGOING_REQUEST_NAME)
    },
    getIncomingCalls () {
      return this.$store.state.sip.calls.filter(call => call.type === INCOMING_REQUEST_NAME)
    },
    showCallBlock () {
      return !!this.getOutboundCall || this.getIncomingCalls.length !== 0
    }
  },
  mounted () {
    const self = this
    window.addEventListener('storage', function (event) {
      if (event.key === 'currentUser' && !event.newValue) {
        self.logout()
      }
    })
  },
  methods: {
    ...mapActions(['logout']),
    sipLogger(value) {
      this.sip.logger && console.log(value)
    },
    getSipUserName () {
      this.sipAccess = JSON.parse(localStorage.getItem('sipAccess'))
    },
    async updateSipToken () {
      try {
        const { data } = await this.$store.dispatch('getSipToken')
        if (data.token) {
          this.sipToken = data.token
        }
      } catch (e) {
        console.log(e)
      }
    },
    _jwtDecode(token) {
      try {
        return jwtDecode(token)
      } catch (error) {
        return undefined
      }
    },
    setUpdateSipTokenTimeout (timeoutId) {
      if (this.sipTokenEndTime !== 0) {
        clearInterval(this.sipTokenTimeoutId)
      }
      this.sipTokenTimeoutId = timeoutId
    },
    async updateSipTokenAndWssConnection() {
      this.flagToUpdateSipToken = false
      await this.updateSipToken()
      updateExtraHeaders(this.sipToken)
      this.updateSipTokenWhenExpired()
    },
    updateSipTokenWhenExpired () {
      const DTO = this._jwtDecode(this.sipToken)
      if (DTO) {
        let time = DTO.exp * 1000 - 60 * 1000 - Date.now()
        time = time < 0 ? 0 : time
        this.sipTokenEndTime = time
        this.setUpdateSipTokenTimeout(setTimeout(async () => {
          await this.updateSipTokenAndWssConnection()
        }, time))
      }
    },
    subscribeTokenExpiredTime (subscribe) {
      if (subscribe && !this.flagToUpdateSipToken) {
        this.flagToUpdateSipToken = subscribe
        this.updateSipTokenWhenExpired()
      }
    },
    async connectSip () {
      this.sipLogger('SIP CONNECTING')
      const displayName = 'FreeFone'

      const simpleUserDelegate = {
        onCallDTMFReceived: (session, tone, duration) => {
          console.log('onCallDTMFReceived: ', session, tone, duration)
        },
        onCallCreated: (session) => {
          this.$store.commit('sip/setCallKeypad', false)
          const sessionType = getSessionType(session)
          const outboundCall = isOutboundCall(session)
          const sessionId = session.id
          if ((this.getOutboundCall && this.getIncomingCalls.length === 1) || this.getIncomingCalls.length === 2) {
            callDecline(session)
            return
          }
          if (!outboundCall && !this.getOutboundCall) {
            this.$store.commit('sip/setCallReceived', true)
          }
          addSession(session, sessionType, session.state)
          const payload = {
            type: sessionType,
            state: session.state,
            status: outboundCall ? 'calling' : 'incoming',
            id: sessionId,
            mute: false,
            hold: false,
            time: 0,
            timerId: null,
            phoneNumber: getNumberOfSession(session),
            dtmf: ''
          }
          this.$store.commit('sip/addCall', payload)
          this.$store.commit('sip/setDisableHoldButton', true)
          this.$store.commit('sip/setDisableMuteButton', true)

          console.log(`[${displayName}] Call created`)

          showNotification({
            title: 'Incoming call',
            body: payload.phoneNumber,
            icon: window.location.origin + '/img/call-pick.svg'
          })

          if (isOutboundCall(session)) {
            return
          }
          const _outboundCall = this.$store.state.sip.calls.find(c => isOutboundCall(getSessionById(c.id)))
          if (_outboundCall) {
            // this.showIncomingCallPopup = true
            return
          }
          this.$store.commit('sip/setMute', false)
          this.$store.commit('sip/setIsCallOnHold', true)
          this.$store.commit('sip/setNotOpenCall', false)
          this.$store.commit('sip/setCallReceived', true)
        },
        onCallAnswered: (session) => {
          const isOutbound = isOutboundCall(session)
          if (!isOutbound) {
            stopAudioRing()
          }
          this.$store.commit('sip/setCallIsActive', true)
          this.$store.commit('sip/updateCall', {
            id: session.id,
            status: 'answered',
            // active: true,
            // disabledButtons: false,
            timerId: setInterval(() => {
              const t = this.$store.state.sip.calls.find(c => c.id === session.id).time
              this.$store.commit('sip/updateCall', {
                id: session.id,
                time: t + 1
              })
            }, 1000)
          })
          this.$store.commit('sip/setDisableHoldButton', false)
          this.$store.commit('sip/setDisableMuteButton', false)
          beep(false)
          console.log(`[${displayName}] Call answered`)
        },
        onCallHangup: (session) => {
          console.log('Hangup: ', getSessionType(session))

          const isIncomingSession = !isOutboundCall(session)
          if (isIncomingSession) {
            stopAudioRing()
            this.$store.commit('sip/setCallReceived', false)
            this.$store.commit('sip/setCallPick', false)
          } else {
            this.$store.commit('sip/setOpenCall', false)
            this.$store.commit('sip/setCallIsActive', false)
            this.$store.commit('sip/setPhone', '')
          }
          beep(false)
          const timerId = this.$store.state.sip.calls.find(c => c.id === session.id)?.timerId
          if (timerId) {
            clearInterval(timerId)
          }
          removeSessionFromCollector(session)
          this.$store.commit('sip/removeCall', session.id)
          if (this.$store.state.sip.calls.length === 0) {
            this.$store.commit('sip/setNotOpenCall', true)
          }
          this.$store.commit('sip/changePhoneNumber', '')
          console.log(`[${displayName}] Call hangup`, smCollector)
        },
        onCallHold: (session, held) => {
          if (isOutboundCall(session)) {
            this.$store.commit('sip/updateCall', {
              id: session.id,
              hold: held
            })
          }
          console.log(`[${displayName}] Call hold ${held}`)
        },
        onCallReceived: async () => {
          playAudioRing()
          console.log(`[${displayName}] Call received`)
        },
        onRegistered: () => {
          this.sip.registered = true
          console.log(`[${displayName}] Registered`)
        },
        onUnregistered: () => {
          this.sip.registered = false
          console.log(`[${displayName}] Unregistered`)
        },
        onServerConnect: () => {
          this.sip.connected = true
          console.log(`[${displayName}] Server connected`)
        },
        onServerDisconnect: () => {
          clearInterval(this.sipTokenTimeoutId)
          this.sip.connected = false
          console.log(`[${displayName}] Server disconnected`)
        }
      }
      const remoteAudio = this.$refs.remoteAudio
      console.log('remoteAudio', remoteAudio)
      const simpleUserOptions = {
        delegate: simpleUserDelegate,
        media: {
          remote: {
            audio: remoteAudio
          }
        },
        userAgentOptions: {
          displayName,
          // logBuiltinEnabled: false
          logLevel: 'debug'
        }
      }
      console.log('simpleUserOptions', simpleUserOptions)

      const wssServer = `wss://${SIP_DOMAIN}:${SIP_PORT}`
      const aor = `sip:${this.sipAccess.sip_username}@${SIP_DOMAIN}`
      console.log('aor', aor)

      // const ha1 = CryptoJS.MD5(`${this.sipAccess.sip_username}:${DOMAIN}:${this.sipAccess.password}`).toString()
      // console.log('HA1B: ', `${this.sipAccess.sip_username}:${DOMAIN}:${this.sipAccess.password}`, ha1, aor)

      if (!this.sipToken) {
        await this.updateSipToken()
      }
      const registererOptions = {
        extraHeaders: [
          `Authorization: JWT ${this.sipToken}`
        ]
      }

      await connectSIP(wssServer, {
        ...simpleUserOptions,
        registererOptions,
        userAgentOptions: {
          ...simpleUserOptions.userAgentOptions
          // authorizationHa1: ha1,
          // authorizationPassword: this.sipAccess.password,
          // authorizationUsername: this.sipAccess.sip_username
          // authorizationUsername: this.username
        },
        aor
      })
      this.subscribeTokenExpiredTime(true)
    },
    sipInit () {
      if (!this.sipUserName || this.sip.connected || this.sip.registered) {
        return
      }
      this.$nextTick(async () => {
        await this.updateSipToken()
        this.subscribeTokenExpiredTime(true)
        this.connectSip()
      })
    },
    async disconnect () {
      await clearInterval(this.sipTokenTimeoutId)
      if (this.sipToken) {
        this.sipLogger('SIP DISCONNECT')
        await unregisterSIP(this.sipToken)
        await disconnectSIP()
      }
    },
    sipMiddleware () {
      if (this.isAdmin) {
        return
      }
      this.sipLogger('SIP MIDDLEWARE')
      this.getSipUserName()
      if (!this.isAuthRoutes || !this.sipUserName) {
        this.disconnect()
        closeSocket()
        return
      }
      this.sipInit()
      if (!socket || !socket.connected) {
        notificationInit()
        initSocket(this.currentUser.token)
        socket.on('tnEvents', (tnEvents) => {
          const isOwnPhone = this.profileNumber === tnEvents.ani
          if (tnEvents.eventType === 'sms_log') {
            const num = tnEvents[isOwnPhone ? 'dnis' : 'ani']
            const payload = {
              message: {
                ...tnEvents,
                direction: isOwnPhone ? 'out' : 'in',
                createdAt: tnEvents[isOwnPhone ? 'sent_on' : 'receive_on'],
                loading: false,
                num
              },
              number: num
            }
            this.$store.dispatch('messages/updateWssMessages', payload)
            if (!isOwnPhone) {
              showNotification({
                title: `Incoming SMS from ${num}`,
                body: payload.message.text,
                icon: window.location.origin + '/img/call-message.svg',
                linkFunction: () => {
                  if (this.$route.name === 'Messages' && this.$route.query.n === num) {
                    return
                  }
                  if (this.$route.name === 'Messages') {
                    this.$router.push({ query: { n: num } })
                  } else {
                    this.$router.push({ name: 'Messages', query: { n: num } })
                  }
                }
              })
            }
          }
          if (['voicemail_transcribe', 'voicemail_log'].includes(tnEvents.eventType)) {
            this.$store.dispatch('calls/addVoice', tnEvents)
          }
          if (tnEvents.eventType === 'call_log') {
            const direction = isOwnPhone ? 'out' : 'in'
            const payload = {
              ...tnEvents,
              direction
            }
            if (direction === 'out') {
              if (!tnEvents.createdAt) return
              payload.createdAt = tnEvents.createdAt
            } else if (tnEvents.answered_on) {
              payload.createdAt = tnEvents.answered_on
            }
            this.$store.commit('calls/addCall', payload)
          }
        })
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
}
</style>

<style scoped>
.v-application::v-deep .display-1 {
  letter-spacing: -0.02em !important;
}
.v-main::v-deep .v-main__wrap {
  display: flex;
  flex-direction: column;
}
</style>
