const keyName = 'missed-call'
const setMissedCall = (phoneNumber) => {
  sessionStorage.setItem(keyName, phoneNumber)
}
const getMissedCall = () => {
  return sessionStorage.getItem(keyName)
}
const removeMissedCall = () => {
  sessionStorage.removeItem(keyName)
}

export {
  setMissedCall,
  getMissedCall,
  removeMissedCall
}
