<template>
  <div class="call-block-wrapper invisible-scrollbar">
    <div style="height: 100%; top:0; left: 0;">
      <template v-if="$props.showDialPad">
        <v-btn
          v-if="!active && isLess1010"
          style="border: none;"
          class="pa-5 mx-auto keypad showKeyword responsive-keypad-fix"
          color="primary"
          outlined
          block
          large
          @click="active = !active;"
        >
          <v-icon left style="width:16px;height:22px">mdi-dialpad</v-icon>
          Show keypad
        </v-btn>
        <div style="height: 100%; max-width: 360px;" v-if="(!isLess1010 ? notOpenCall : active) && !isAdminPortal">
          <v-col
            :style="{
              'position': (isDesktop || !active) && !showKeypad ? 'relative' : 'fixed',
              'inset': showKeypad && active ? '70px 0 0 0' : '0',
              'background': showKeypad && active ? '#fff' : '',
              'height': (isDesktop || !active) && !showKeypad || isLess575 ? '100%' : 'calc(100vh - 70px)',
            }"
            cols="12"
            :sm="GetToggleSidebar ? 2 : 3"
            class="pa-0 d-flex flex-column d-sm-block keypad-container"
            style="min-width: 360px; background-color: white;"
            @click="active = false"
          >
            <div
              class="py-md-4 px-0 rightCallNumber"
              :style="{ 'margin-top': isDesktop || !showKeypad ? '0' : 'auto' }"
              @click.stop
            >
              <h6
                v-if="isDesktop || (showKeypad && active)"
                class="px-4 caption font-weight-normal"
              >
                Call as
              </h6>
              <p v-if="isDesktop || (showKeypad && active)" class="px-4 mb-0">
                {{ formatPhone(profile.number) }}
              </p>

              <div class="d-flex align-center">
                <v-text-field
                  v-if="(isDesktop && !showKeypad) || (showKeypad && active) || active"
                  v-model="phoneUsername"
                  ref="numberPhoneFieldMain"
                  height="68px"
                  type="tel"
                  dense
                  :maxLength="isNumberPhoneUsername ? maxPhoneNumberLength : maxUserNameLength"
                  outlined
                  class="enterPhone mb-0"
                  label="Enter name or phone number"
                  elevation="0"
                  solo
                  @paste.prevent="onPasteNumber"
                  @input="phoneFieldFilter($event)"
                >
                </v-text-field>
                <v-tooltip top content-class="v-tooltip--top" :disabled="!profileBalanceIsZero">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="callButtonPosition">
                      <v-btn
                        v-if="isDesktop"
                        :disabled="isNumberPhoneUsername ? (!isValidPhoneLength || blockCallBtn) : !phone"
                        icon
                        @click="toCall()"
                      >
                        <v-icon v-if="isNumberPhoneUsername ? (!isValidPhoneLength || blockCallBtn) : !phone" class="call-icon">mdi-phone</v-icon>
                        <v-img
                          v-else
                          style="cursor: pointer"
                          :src="require('../../assets/svg/call-pick.svg')"
                          contain
                          width="40"
                          height="40"
                          max-width="min-content"
                          class="mx-auto"
                        />
                      </v-btn>
                    </div>
                  </template>
                  <span>Your balance is 0, only US or Canada is available</span>
                </v-tooltip>
              </div>

              <v-divider v-if="isDesktop" />

              <div v-if="(!isLess1010 || (isLess1010 && active)) && !sipPhone && phoneNumberByContact.length !== 0" class="contacts-wrapper thin-scroll">
                <div v-for="item in phoneNumberByContact" :key="item.uuid" class="contact-block" @click="setPhoneByContact(item)">
                  <v-img
                    :src="require('../../assets/svg/call-avatar.svg')"
                    contain
                    width="48"
                    height="48"
                    max-width="min-content"
                    class="mx-auto"
                    style="width: fit-content"
                  />
                  <div style="width: 100%">
                    <div>{{ item.fullName }}</div>
                    <div class="incoming-call-popup-inc">{{ formatPhone(item.phone_number) }}</div>
                  </div>
                </div>
              </div>

              <div
                class="dialpad-block"
                style=" bottom: 0; left: 0; width: 100%; background-color: #FFFFFF;"
              >
                <div>
                  <v-row
                    :class="active ? '' : 'd-none'"
                    class="mobile-keypad ma-0 mx-auto"
                    style="max-width: 330px"
                  >
                    <v-col
                      v-for="(item, i) in calculateData"
                      :key="i"
                      cols="4"
                      class="pa-2 d-flex justify-center"
                    >
                      <v-btn
                        plain
                        :ripple="false"
                        :style="{ color: '#5657af!important' }"
                        class="calculatePhoneOnCall d-flex flex-column mb-6"
                        @click="calculatePhoneOnCall(item.number, 'numberPhoneFieldMain')"
                      >
                        <span style="min-height:17px">{{ item.words }}</span>
                        <span class="display-1">{{ item.number }}</span>
                      </v-btn>
                    </v-col>
                    <v-icon color="white">mdi-phone</v-icon>
                  </v-row>
                  <GoogleAdSense adSlot="7657585430" v-show="!isLess1010 && !active" styles="display:inline-block;max-width: 360px; min-width: 300px; width: 360px; height: 360px; margin-bottom: 60px" sequence="2" />
                  <!--                  <div v-show="!isLess1010" style="background: #5758B0;display:inline-block;max-width: 360px; min-width: 300px; width: 360px; height: 360px; margin-bottom: 60px" />-->
                </div>
                <div class="text-center"
                     v-if="!isLess575"
                     :style="{ 'max-width': maxWidth, bottom: 0 }"
                     style="position: absolute; width: inherit">
                  <v-divider></v-divider>
                  <v-btn
                    v-if="!active && !isLess1010"
                    :style="$vuetify.breakpoint.smAndUp && !showKeypad ? '' : 'background: #5758B0; right: 0!important;'"
                    :class="{ 'responsive-keypad-fix': showKeypad }"
                    class="pa-5 mx-auto keypad showKeyword"
                    style="border: none;"
                    color="primary"
                    outlined
                    block
                    large
                    @click="active = !active;"
                  >
                    <v-icon left style="width:16px;height:22px">mdi-dialpad</v-icon>
                    <span v-if="!isSmall && !isMiddle">
                  Show keypad
                </span>
                  </v-btn>
                  <v-btn
                    v-if="active"
                    color="primary"
                    style="border: none; background-color: #FFFFFF"
                    block
                    class="pa-5 mb-5 mb-sm-0 mx-auto"
                    outlined
                    @click="active = !active"
                  >
                <span class="primary--text">
                  <v-icon color="primary">mdi-unfold-less-horizontal</v-icon>
                  Hide keypad
                </span>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
        </div>
      </template>
      <div style="width: 360px; overflow-y: auto; overflow-x: hidden;" class="invisible-scrollbar">
        <div v-if="!getOutboundCall && getIncomingCalls.length === 2 || getOutboundCall && getIncomingCalls.length === 1"
             style="width: 360px;z-index: 10000;height: fit-content;"
             :style="{
                position: !isLess1010 ? 'absolute' : 'fixed',
                inset: !isLess1010 ? '0' : '80px 0 0 0'
              }">
          <v-card
            class="incoming-call-popup"
            style="position: absolute;width: 310px;"
            elevation="3"
          >
            <v-row>
              <v-col cols="3" sm="3">
                <v-img
                  :src="require('../../assets/svg/call-avatar.svg')"
                  contain
                  width="48"
                  height="48"
                  max-width="min-content"
                  class="mx-auto"
                />
              </v-col>
              <v-col cols="6" sm="6">
                <div class="incoming-call-popup-inc">Incoming call <span v-if="getPopUpCall.status === 'answered'">{{ formatTime(getPopUpCall) }}</span></div>
                <div v-if="getContactName(getPopUpCall.phoneNumber)">{{ getContactName(getPopUpCall.phoneNumber) }}</div>
                <div>{{ formatPhone(getPopUpCall.phoneNumber) || '' }}</div>
              </v-col>
            </v-row>

            <template v-if="getPopUpCall.status !== 'answered'">
              <div class="mt-4 incoming-call-popup-action-btn" @click="holdAndAnswer">
                <svg viewBox="0 0 24 24" fill="green" width="22px" height="22px">
                  <path d="M16.02 14.46l-2.62 2.62a16.141 16.141 0 0 1-6.5-6.5l2.62-2.62a.98.98 0 0 0 .27-.9L9.15 3.8c-.1-.46-.51-.8-.98-.8H4.02c-.56 0-1.03.47-1 1.03a17.92 17.92 0 0 0 2.43 8.01 18.08 18.08 0 0 0 6.5 6.5 17.92 17.92 0 0 0 8.01 2.43c.56.03 1.03-.44 1.03-1v-4.15c0-.48-.34-.89-.8-.98l-3.26-.65c-.33-.07-.67.04-.91.27z"></path>
                </svg>
                <div>Hold and Answer</div>
              </div>
              <div class="mt-4 incoming-call-popup-action-btn" @click="hangupAndAnswer">
                <svg viewBox="0 0 24 24" fill="gray" width="22px" height="22px">
                  <path d="M3.00232 2H7.53232C8.22232 2 8.82232 2.47 8.99232 3.14L9.81232 6.49C9.94232 7 9.79232 7.54 9.42232 7.91L6.48232 10.85C8.07232 13.56 10.3323 15.82 13.0323 17.42L16.0123 14.44C16.4323 14.02 16.9923 13.94 17.4323 14.05L20.7823 14.89C21.4523 15.05 21.9223 15.65 21.9223 16.34V20.93C21.9223 21.48 21.4823 21.93 20.9423 21.93C14.0423 21.93 2.90232 15.45 2.00232 3.05C1.96232 2.48 2.43232 2 3.00232 2ZM14.6273 2.64645L17.4223 5.44145L20.2173 2.64645L21.2759 3.705L18.4809 6.5L21.2759 9.295L20.2173 10.3536L17.4223 7.55855L14.6273 10.3536L13.5687 9.295L16.3637 6.5L13.5687 3.705L14.6273 2.64645Z"></path>
                </svg>
                <div>Hangup and Answer</div>
              </div>
              <div class="mt-4 incoming-call-popup-action-btn" @click="rejectIncomingCall">
                <svg viewBox="0 0 24 24" fill="#eb5757" width="22px" height="22px">
                  <path d="M23.62 11.27c-2.03-1.72-4.46-3-7.12-3.69C15.06 7.21 13.56 7 12 7s-3.06.21-4.5.58c-2.66.69-5.08 1.96-7.12 3.69-.45.38-.5 1.07-.08 1.49l.67.67 2.26 2.26c.33.33.85.39 1.25.13l2.56-1.64c.29-.18.46-.5.46-.84V9.65C8.93 9.23 10.44 9 12 9s3.07.23 4.5.65v3.68c0 .34.17.66.46.84l2.56 1.64c.4.25.92.2 1.25-.13l2.26-2.26.67-.67c.41-.41.37-1.1-.08-1.48z"></path>
                </svg>
                <div>Reject</div>
              </div>
            </template>
            <template v-else>
              <div class="incoming-call-popup-action-btn-answered">
                <div>
                  <v-img
                    style="cursor: pointer"
                    :src="require('../../assets/svg/call-message.svg')"
                    contain
                    width="32"
                    height="32"
                    max-width="min-content"
                    class="mx-auto"
                    @click="sendMessage(getPopUpCall.phoneNumber)"
                  />
                </div>
                <div>
                  <v-img
                    @click="muteCall(getPopUpCall)"
                    style="cursor: pointer"
                    :src="
                        require(`../../assets/svg/${
                          getPopUpCall && getPopUpCall.mute ? 'call-mute.svg' : 'call-mute-active.svg'
                        }`)
                      "
                    contain
                    width="32"
                    height="32"
                    max-width="min-content"
                    class="mx-auto"
                  />
                </div>
                <div>
                  <v-img
                    @click="hangup(getPopUpCall)"
                    style="cursor: pointer"
                    :src="require('../../assets/svg/call-end.svg')"
                    contain
                    width="32"
                    height="32"
                    max-width="min-content"
                    class="mx-auto"
                  />
                </div>
              </div>
            </template>
          </v-card>
        </div>
        <v-col
          :style="{
            'position': !isLess1010 ? 'relative' : 'fixed',
            'inset': !isLess1010 ? '0' : '70px 0 0 0',
            'background': '#fff',
            'height': '100%',
          }"
          v-if="getOutboundCall"
          cols="12"
          sm="3"
          class="pa-0"
          style="position: relative; min-width:360px; margin-bottom: 30px"
        >
          <div class="call-block-out invisible-scrollbar">
            <div class="d-flex justify-center align-center pa-0 mt-16 less1010" style="position: relative;">
              <div class="d-flex flex-column align-center">
                <div class="mb-16">
                  <v-img
                    :src="require('../../assets/svg/call-avatar.svg')"
                    contain
                    width="72"
                    height="72"
                    max-width="min-content"
                    class="mx-auto"
                  />
                  <v-list>
                    <v-list-item>
                      <v-list-item-content class="w-100 text-center flex flex-column align-center">
                        <v-list-item-title v-if="getContactName(getOutboundCall.phoneNumber)" class="font-weight-medium" style="max-width: 200px">
                          {{ getContactName(getOutboundCall.phoneNumber) }}
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium" style="max-width: 200px">
                          {{ formatPhone(getOutboundCall.phoneNumber) }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="getOutboundCall.status !== 'answered'"
                          class="mt-4 caption"
                        >
                          Calling...
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else class="mt-4 caption">
                          {{ formatTime(getOutboundCall) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>

                <v-row v-if="callOption" style="max-width: 250px">
                  <v-col
                    cols="6"
                    sm="6"
                    class="d-flex flex-column align-center pa-0 mb-8"
                  >
                    <div>
                      <v-img
                        style="cursor: pointer"
                        :src="require('../../assets/svg/call-message.svg')"
                        contain
                        width="72"
                        height="72"
                        max-width="min-content"
                        class="mx-auto"
                        @click="sendMessage(getOutboundCall.phoneNumber)"
                      />
                    </div>
                    <v-list-item-subtitle class="text-center caption mt-3"
                    >Message
                    </v-list-item-subtitle>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="d-flex flex-column align-center pa-0 mb-8"
                  >
                    <div>
                      <v-img
                        @click="muteCall(getOutboundCall)"
                        :style="
                        disableMuteButton
                          ? 'pointer-events: none'
                          : 'cursor: pointer'
                      "
                        :src="
                        require(`../../assets/svg/${
                          getOutboundCall && getOutboundCall.mute ? 'call-mute.svg' : 'call-mute-active.svg'
                        }`)
                      "
                        contain
                        width="72"
                        height="72"
                        max-width="min-content"
                        class="mx-auto"
                      />
                    </div>
                    <v-list-item-subtitle class="text-center caption mt-3"
                    >{{ getOutboundCall && getOutboundCall.mute ? 'Unmute' : 'Mute' }}
                    </v-list-item-subtitle>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="d-flex flex-column align-center pa-0 mb-8"
                  >
                    <div>
                      <v-img
                        :style="`${
                        disableHoldButton
                          ? 'cursor: no-drop; pointer-events: none'
                          : 'cursor: pointer; pointer-events: inherit'
                      }`"
                        :src="
                        require(`../../assets/svg/${
                          getOutboundCall && getOutboundCall.hold
                            ? 'call-hold.svg'
                            : 'call-hold-active.svg'
                        }`)
                      "
                        @click="hold(null)"
                        contain
                        width="72"
                        height="72"
                        max-width="min-content"
                        class="mx-auto"
                      />
                    </div>
                    <v-list-item-subtitle class="text-center caption mt-3"
                    >{{ getOutboundCall && getOutboundCall.hold ? 'Unhold' : 'Hold' }}
                    </v-list-item-subtitle>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="d-flex flex-column align-center pa-0 mb-8"
                  >
                    <div>
                      <v-img
                        @click="getOutboundCall.status === 'answered' && keypadFunction()"
                        :style="`${
                          getOutboundCall.status !== 'answered'
                            ? 'cursor: no-drop; pointer-events: none'
                            : 'cursor: pointer; pointer-events: inherit'
                        }`"
                        :src="require(`../../assets/svg/${getOutboundCall.status === 'answered' ? 'call-keypad.svg' : 'call-keybord-active.svg'}`)"
                        contain
                        width="72"
                        height="72"
                        max-width="min-content"
                        class="mx-auto"
                      />
                    </div>
                    <v-list-item-subtitle class="text-center caption mt-3"
                    >Keypad
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>

                <div v-if="callKeypad" class="callKeyboard">
                  <div class="d-flex align-center">
                    <v-text-field
                      ref="numberPhoneFieldSecond"
                      :value="getOutboundCall.dtmf"
                      @input="updateDtmf($event, getOutboundCall)"
                      @keypress="onlyNumbers"
                      outlined
                      class="enterPhone mb-0"
                      elevation="0"
                      hide-details
                    >
                    </v-text-field>

                    <v-btn
                      class="callButtonPosition"
                      icon
                      @click="displayCallOption"
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </div>
                  <!--                <v-bottom-navigation-->
                  <!--                    v-model="value"-->
                  <!--                    :input-value="active"-->
                  <!--                    color="primary"-->
                  <!--                    style="flex-direction: column"-->
                  <!--                    height="auto"-->
                  <!--                >-->
                  <!--                  <v-divider></v-divider>-->

                  <v-row
                    class="mobile-keypad ma-0 mx-auto"
                    style="max-width: 330px"
                  >
                    <v-col
                      v-for="(item, i) in calculateData"
                      :key="i"
                      cols="4"
                      class="pa-2 d-flex justify-center"
                    >
                      <v-btn
                        :class="{ calculateNumberButton }"
                        plain
                        :ripple="false"
                        @click="calculatePhoneOnCall(item.number, 'numberPhoneFieldSecond')"
                        class="calculatePhoneOnCall d-flex flex-column grey-33 mb-6"
                      >
                        <span style="min-height:17px">{{ item.words }}</span>
                        <span class="display-1">{{ item.number }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <!--                </v-bottom-navigation>-->
                </div>

                <div class="mt-16">
                  <v-img
                    @click="hangup(getOutboundCall)"
                    style="cursor: pointer"
                    :src="require('../../assets/svg/call-end.svg')"
                    contain
                    width="72"
                    height="72"
                    max-width="min-content"
                    class="mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-col>

        <v-col
          :style="{
            'position': !isLess1010 ? 'relative' : 'fixed',
            'inset': !isLess1010 ? '0' : '70px 0 0 0',
            'background': '#fff',
            'height': '100%',
          }"
          v-if="callReceived"
          cols="12"
          sm="3"
          class="pa-0 call-block-out invisible-scrollbar"
          style="position: relative; min-width: 360px; margin-bottom: 30px"
        >
          <div class="d-flex mt-16 flex-column align-center less1010">
            <div class="mb-16">
              <v-img
                :src="require('../../assets/svg/call-avatar.svg')"
                contain
                width="72"
                height="72"
                max-width="min-content"
                class="mx-auto"
              />
              <v-list>
                <v-list-item>
                  <v-list-item-content class="w-100 text-center flex flex-column align-center">
                    <v-list-item-title v-if="getContactName(getIncomingCalls[0].phoneNumber)" class="font-weight-bold" style="max-width: 200px">
                      {{ getContactName(getIncomingCalls[0].phoneNumber) }}
                    </v-list-item-title>
                    <v-list-item-title class="font-weight-bold" style="max-width: 200px">
                      {{ formatPhone(getIncomingCalls[0].phoneNumber) }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="getIncomingCalls[0].status !== 'answered'"
                      class="mt-4 caption"
                    >
                      Incoming Call
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else class="mt-4 caption">
                      {{ formatTime(getIncomingCalls[0]) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>

            <v-row style="max-width: 250px" v-if="callOption">
              <v-col
                cols="6"
                sm="6"
                class="d-flex flex-column align-center pa-0 mb-8"
              >
                <div>
                  <v-img
                    style="cursor: pointer"
                    :src="require('../../assets/svg/call-message.svg')"
                    contain
                    width="72"
                    height="72"
                    max-width="min-content"
                    class="mx-auto"
                    @click="sendMessage(getIncomingCalls[0].phoneNumber)"
                  />
                </div>
                <v-list-item-subtitle class="text-center caption mt-3"
                >Message
                </v-list-item-subtitle>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                class="d-flex flex-column align-center pa-0 mb-8"
              >
                <div>
                  <v-img
                    @click="muteCall(getIncomingCalls[0])"
                    :style="
                    disableMuteButton ? 'pointer-events: none' : 'cursor: pointer'
                  "
                    :src="
                    require(`../../assets/svg/${
                      getIncomingCall && getIncomingCall.mute ? 'call-mute.svg' : 'call-mute-active.svg'
                    }`)
                  "
                    contain
                    width="72"
                    height="72"
                    max-width="min-content"
                    class="mx-auto"
                  />
                </div>
                <v-list-item-subtitle class="text-center caption mt-3"
                >{{ getIncomingCall && getIncomingCall.mute ? 'Unmute' : 'Mute' }}
                </v-list-item-subtitle>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                class="d-flex flex-column align-center pa-0 mb-8"
              >
                <!--              <div>-->
                <!--                <v-img-->
                <!--                  :style="`${-->
                <!--                    disableHoldButton-->
                <!--                      ? 'cursor: no-drop; pointer-events: none'-->
                <!--                      : 'cursor: pointer; pointer-events: inherit'-->
                <!--                  }`"-->
                <!--                  :src="-->
                <!--                    require(`../../assets/svg/${-->
                <!--                      getIncomingCall && getIncomingCall.hold ? 'call-hold.svg' : 'call-hold-active.svg'-->
                <!--                    }`)-->
                <!--                  "-->
                <!--                  @click="hold('inbound')"-->
                <!--                  contain-->
                <!--                  width="72"-->
                <!--                  height="72"-->
                <!--                  max-width="min-content"-->
                <!--                  class="mx-auto"-->
                <!--                />-->
                <!--              </div>-->
                <!--              <v-list-item-subtitle class="text-center caption mt-3"-->
                <!--              >Hold-->
                <!--              </v-list-item-subtitle>-->
              </v-col>
              <v-col
                cols="6"
                sm="6"
                class="d-flex flex-column align-center pa-0 mb-8"
              >
                <div>
                  <v-img
                    @click="getIncomingCall.status === 'answered' && keypadFunction()"
                    :style="`${
                          getIncomingCall.status !== 'answered'
                            ? 'cursor: no-drop; pointer-events: none'
                            : 'cursor: pointer; pointer-events: inherit'
                        }`"
                    :src="require(`../../assets/svg/${getIncomingCall.status === 'answered' ? 'call-keypad.svg' : 'call-keybord-active.svg'}`)"
                    contain
                    width="72"
                    height="72"
                    max-width="min-content"
                    class="mx-auto"
                  />
                </div>
                <v-list-item-subtitle class="text-center caption mt-3"
                >Keypad
                </v-list-item-subtitle>
              </v-col>
            </v-row>
            <div v-if="callKeypad" class="callKeyboard">
              <div class="d-flex align-center">
                <v-text-field
                  :value="getIncomingCall.dtmf"
                  @input="updateDtmf($event, getIncomingCall)"
                  ref="numberPhoneFieldThird"
                  @keypress="onlyNumbers"
                  outlined
                  class="enterPhone mb-0"
                  elevation="0"
                  hide-details
                >
                </v-text-field>

                <v-btn
                  class="callButtonPosition"
                  icon
                  @click="displayCallOption"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </div>
              <!--                <v-bottom-navigation-->
              <!--                    v-model="value"-->
              <!--                    :input-value="active"-->
              <!--                    color="primary"-->
              <!--                    style="flex-direction: column"-->
              <!--                    height="auto"-->
              <!--                >-->
              <!--                  <v-divider></v-divider>-->

              <v-row
                class="mobile-keypad ma-0 mx-auto"
                style="max-width: 330px"
              >
                <v-col
                  v-for="(item, i) in calculateData"
                  :key="i"
                  cols="4"
                  class="pa-2 d-flex justify-center"
                >
                  <v-btn
                    :class="{ calculateNumberButton }"
                    plain
                    :ripple="false"
                    @click="calculatePhoneOnCall(item.number, 'numberPhoneFieldThird')"
                    class="calculatePhoneOnCall d-flex flex-column grey-33 mb-6"
                  >
                    <span style="min-height:17px">{{ item.words }}</span>
                    <span class="display-1">{{ item.number }}</span>
                  </v-btn>
                </v-col>
              </v-row>

              <!--                </v-bottom-navigation>-->
            </div>

            <div class="mt-16 d-flex justify-center align-center" style="gap: 40px">
              <v-img
                @click="hangup(getIncomingCalls[0])"
                style="cursor: pointer"
                :src="require('../../assets/svg/call-end.svg')"
                contain
                width="72"
                height="72"
                max-width="min-content"
                class="mx-auto"
              />
              <v-img
                v-if="getIncomingCalls[0].status !== 'answered'"
                @click="answerCall(getIncomingCalls[0])"
                style="cursor: pointer"
                :src="require('../../assets/svg/call-pick.svg')"
                contain
                width="72"
                height="72"
                max-width="min-content"
                class="mx-auto"
              />
            </div>
          </div>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import resizeMixin from '@/mixins/resize.mixin'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import {
  beep,
  call,
  callDecline,
  callHangup,
  getSessionById,
  callMute,
  callHold,
  callAnswer,
  sendDTMF,
  INCOMING_REQUEST_NAME,
  OUTGOING_REQUEST_NAME,
  isOutboundCall
} from '@/utilities/sessionManager'
import npaUsCad from '@/constant/npa_us_cad'
import moment from 'moment'
import GoogleAdSense from '../GoogleAdSense.vue'
import { formatPhone, getCountryIsoBy } from '../../utilities/formatPhone'

export default {
  name: 'CallBlock',
  mixins: [resizeMixin],
  props: {
    showDialPad: {
      type: Boolean,
      default: false
    }
  },
  components: { GoogleAdSense },
  data: () => {
    return {
      isMainInputFocused: false,
      phoneUsername: '',
      maxlength: 11, // 13
      minlength: 10,
      maxPhoneNumberLength: 11,
      minPhoneNumberLength: 10,
      maxUserNameLength: 100,
      numberFilterList: ['2', '3', '4', '5', '6', '7', '8', '9'],
      sipToken: null,
      sipTokenEndTime: 0,
      sipTokenTimeoutId: null,
      flagToUpdateSipToken: false,
      // validPhoneLength: 11,
      disabledButtons: true,
      mutedDisabled: true,
      right_box: 'contact_history',
      username: '',
      password: '',
      audioConstraints: {
        audio: true,
        video: false
      },
      simpleUser: null,
      plan: {},
      startTimer: false,
      sipUserRegistered: false,

      // ---------- //
      profile: [],
      value: 1,
      active: false,
      calculateData: [
        { number: 1, words: '' },
        { number: 2, words: 'ABC' },
        { number: 3, words: 'DEF' },
        { number: 4, words: 'GHI' },
        { number: 5, words: 'JKL' },
        { number: 6, words: 'MNO' },
        { number: 7, words: 'PQRS' },
        { number: 8, words: 'TUV' },
        { number: 9, words: 'WXYZ' },
        { number: '*', words: '' },
        { number: 0, words: '' },
        { number: '#', words: '' }
      ],
      calculateNumberButton: 'calculateNumberButton',
      isReceivedCall: false,
      callEnd: false
    }
  },
  computed: {
    ...mapState(['sip/phoneNumber', 'showPeople']),
    ...mapGetters(['getAlertStatus', 'GetToggleSidebar', 'userProfile']),
    ...mapGetters({
      contacts: 'contacts/contacts'
    }),
    currentSIPSession() {
      return this.$store.state.sip.currentSIPSession
    },
    calls() {
      return this.$store.state.sip.calls.length
    },
    mute() {
      return this.$store.state.sip.mute
    },
    notOpenCall() {
      return this.$store.state.sip.notOpenCall
    },
    disableMuteButton() {
      return this.$store.state.sip.disableMuteButton
    },
    disableHoldButton() {
      return this.$store.state.sip.disableHoldButton
    },
    callKeypad() {
      return this.$store.state.sip.callKeypad
    },
    callReceived() {
      return !this.getOutboundCall && this.getIncomingCalls.length !== 0
    },
    getIncomingCalls() {
      return this.$store.state.sip.calls.filter(call => call.type === INCOMING_REQUEST_NAME)
    },
    callIsActive() {
      return this.$store.state.sip.callIsActive
    },
    callOption() {
      return this.$store.state.sip.callOption
    },
    profileBalanceIsZero() {
      return +this.userProfile.balance === 0
    },
    sipPhone() {
      return this.$store.state.sip.phone
    },
    phone() {
      if (!this.isStringNumeric(this.phoneUsername).valid) return this.sipPhone
      return this.phoneUsername
    },
    phoneOnCall() {
      return this.$store.state.sip.phoneOnCall
    },
    blockCallBtn() {
      if (this.profileBalanceIsZero) {
        if (this.phoneLength === 10) {
          return !this.isNpaUsCad(this.phone.slice(0, 3))
        }
        if (this.phoneLength === 11) {
          return !this.isNpaUsCad(this.phone.slice(1, 4))
        }
      }
      return false
    },
    getPopUpCall() {
      if (this.getOutboundCall) {
        return this.getIncomingCalls[0]
      }
      if (!this.getOutboundCall && this.getIncomingCalls.length === 2) {
        return this.getIncomingCalls[1]
      }
      return this.getIncomingCalls[0]
    },
    media() {
      const audio = document.getElementById('remoteAudio')
      const audio2 = document.getElementById('localAudio')
      return {
        stream: this.audioConstraints,
        render: {
          remote: audio,
          local: audio2
        }
      }
    },
    phoneLength() {
      return this.phone.length
    },
    phoneNumberByContact() {
      if (!this.phoneUsername) return []
      return this.contacts.filter(item => item.fullName && item.fullName.includes(this.phoneUsername.toLowerCase()))
    },
    isNumberPhoneUsername() {
      return this.isStringNumeric(this.phoneUsername)?.valid
    },
    isValidPhoneUserNameLength() {
      if (!this.isNumberPhoneUsername) return true
      const _phoneLength = this.phoneUsername.length
      if ((_phoneLength <= 11 && [+this.phoneUsername[0], +this.phoneUsername[1]].includes(0)) || (_phoneLength === 11 && +this.phone[0] !== 1)) {
        return false
      }
      // if (this.phoneLength === 12 && ([+this.phone[1], +this.phone[2]].includes(0) || +this.phone[1] !== 1)) {
      //   return false
      // }
      // if (this.phoneLength === 13 && ([+this.phone[2], +this.phone[3]].includes(0) || +this.phone[2] !== 1)) {
      //   return false
      // }
      return _phoneLength <= this.maxlength && _phoneLength >= this.minlength
    },
    isValidPhoneLength() {
      const _phoneLength = this.isNumberPhoneUsername ? this.phoneLength : this.phoneUsername.length
      // const _phone = this.isNumberPhoneUsername ? this.phone : this.phoneUsername
      // if ((_phoneLength <= 11 && [+_phone[0], +_phone[1]].includes(0)) || (_phoneLength === 11 && +_phone[0] !== 1)) {
      //   return false
      // }
      // if (this.phoneLength === 12 && ([+this.phone[1], +this.phone[2]].includes(0) || +this.phone[1] !== 1)) {
      //   return false
      // }
      // if (this.phoneLength === 13 && ([+this.phone[2], +this.phone[3]].includes(0) || +this.phone[2] !== 1)) {
      //   return false
      // }
      return _phoneLength <= this.maxPhoneNumberLength && _phoneLength >= this.minPhoneNumberLength
    },
    getOutboundCall () {
      return this.$store.state.sip.calls.find(call => call.type === OUTGOING_REQUEST_NAME)
    },
    getIncomingCall () {
      return this.$store.state.sip.calls.find(call => call.type === INCOMING_REQUEST_NAME)
    },
    isLess1010 () { return this.window.width <= 1010 },
    isLess1122 () { return this.window.width < 1122 },
    isDesktop () { return this.$vuetify.breakpoint.smAndUp },
    isDesktop2 () { return this.window.width > 937 },
    isLess575 () { return this.window.width < 575 },
    isMiddle () { return this.window.width > 959 && this.window.width <= 1010 },
    isSmall () { return this.window.width > 575 && this.window.width < 959 },
    maxWidth () { return this.active ? '' : this.isSmall ? '72px' : this.isMiddle ? '256px' : '360px' },
    showKeypad () { return this.window.width > 575 && (this.window.width <= 1010 || this.window.width < 938) },
    isAdminPortal () { return this.$route.path.includes('/admin') }
  },
  methods: {
    ...mapActions(['getUserProfile']),
    ...mapMutations('sip', [
      'setIncomingPopup',
      'setCallKeypad',
      'setCallReceived',
      'setDisableHoldButton',
      'setDisableMuteButton',
      'setCurrentSIPSession',
      'setMute',
      'setIsCallOnHold',
      'setNotOpenCall',
      'setCallIsActive',
      'setCallPick',
      'setOpenCall',
      'setPhone',
      'setPhoneOnCall',
      'setCallOption'
    ]),
    ...mapActions('contacts', ['getContacts']),
    setPhoneByContact(contact) {
      this.phoneUsername = contact.fullName
      this.setPhone(contact.phone_number)
    },
    formatPhone,
    formatTime(call) {
      if (call) {
        const d = moment.utc(call.time * 1000)
        return d.format('mm:ss')
      }
      return ''
    },
    isNpaUsCad(npa) {
      return npaUsCad.find(item => npa === item)
    },
    closeKeypad() {
      if (this.window.width < 575 && this.active) {
        this.active = false
      }
    },
    closeIncomingPopup() {
      this.setIncomingPopup(false)
    },
    async answerCall(call) {
      const s = getSessionById(call.id)
      const res = await callAnswer(s)
      if (res === 'success') {
        console.log('ANSWERED')
      }
    },
    async rejectIncomingCall() {
      const call = this.getOutboundCall ? this.getIncomingCalls[0] : this.getIncomingCalls[1]
      if (call) {
        const s = getSessionById(call.id)
        const res = await callDecline(s)
        if (res === 'success') {
          this.setIncomingPopup(false)
          this.setCallPick(false)
        }
      }
      this.closeIncomingPopup()
    },
    async holdAndAnswer() {
      if (this.getOutboundCall) {
        await this.hold(true)
        await this.answerCall(this.getIncomingCalls[0])
        return
      }
      if (!this.getOutboundCall && this.getIncomingCalls.length === 2) {
        this.muteCall(this.getIncomingCalls[0], true)
        await this.answerCall(this.getIncomingCalls[1])
      }
    },
    async hangupAndAnswer() {
      if (this.getOutboundCall) {
        await this.hangup(this.getOutboundCall)
        await this.answerCall(this.getIncomingCalls[0])
        return
      }
      if (!this.getOutboundCall && this.getIncomingCalls.length === 2) {
        await this.hangup(this.getIncomingCalls[0])
        await this.answerCall(this.getIncomingCalls[0])
      }
    },
    sendMessage(phone) {
      if (this.$route.name !== 'Messages') {
        this.$store.commit('changeMenu')
        this.$router.push({ path: '/messages', query: { n: phone } })
        return
      }
      this.$store.commit('sendMessageOnCall', phone)
    },
    keypadFunction() {
      this.setCallKeypad(true)
      this.setCallOption(false)
    },

    displayCallOption() {
      this.setCallKeypad(false)
      this.setCallOption(true)
    },
    focusFieldByRefName(index, refName) {
      if (this.$refs[refName]) {
        this.$nextTick(() => {
          this.$refs[refName].$refs.input.setSelectionRange(index, index)
          this.$refs[refName].$refs.input.focus()
        })
      }
    },
    checkOverMaxlength(val) {
      return val.length > this.maxlength
    },
    isStringNumeric(str) {
      const cleanedString = str.replace(/[()\-./\s\u200B-\u200F\u202A-\u202E\uFEFF]/g, '')
      return {
        valid: !isNaN(+cleanedString),
        value: cleanedString
      }
    },
    async sendTone(session, tone) {
      try {
        await sendDTMF(session, tone)
      } catch (error) {
        console.log(error)
      }
    },
    updateDtmf(event, call) {
      if (call) {
        const callId = call.id
        const lastEl = event.length > 0 && event.slice(-1)
        if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '0', '*', '#'].includes(lastEl) && lastEl && event.length > call.dtmf.length) {
          this.sendTone(getSessionById(callId), lastEl)
        }
        this.$store.commit('sip/updateCall', {
          id: callId,
          dtmf: event
        })
      }
    },
    async calculatePhoneOnCall(number, refName) {
      const caretIndex = this.$refs[refName]?.$refs?.input?.selectionStart
      const key = refName !== 'numberPhoneFieldMain' ? 'phoneOnCall' : 'phoneUsername'
      const call = refName === 'numberPhoneFieldSecond' ? this.getOutboundCall : this.getIncomingCall
      if (refName === 'numberPhoneFieldMain' ? this[key].length === caretIndex : call.dtmf.length === caretIndex) {
        if (refName !== 'numberPhoneFieldMain') {
          const session = getSessionById(call.id)
          this.$store.commit('sip/updateCall', {
            id: call.id,
            dtmf: call.dtmf + number
          })
          await this.sendTone(session, number)
        } else {
          this.phoneUsername = this[key] + number
        }
      } else {
        if (refName !== 'numberPhoneFieldMain') {
          this.$store.commit('sip/updateCall', {
            id: call.id,
            dtmf: call.dtmf.slice(0, caretIndex) + number + call.dtmf.slice(caretIndex)
          })
        } else {
          this.phoneUsername = this[key].slice(0, caretIndex) + number + this[key].slice(caretIndex)
        }
      }
      this.focusFieldByRefName(caretIndex + 1, refName)
    },
    onlyNumbers(event) {
      if (!/\d/.test(event.key) && event.key !== '.' && event.key !== '*' && event.key !== '#') return event.preventDefault()
    },
    phoneFieldFilter (value) {
      const validator = this.isStringNumeric(value)
      if (!validator.valid) return
      const minMaxByDialCode = getCountryIsoBy(this.phone)
      this.maxPhoneNumberLength = minMaxByDialCode.max ?? this.maxlength
      this.minPhoneNumberLength = minMaxByDialCode.min ?? this.minlength
    },
    onPasteNumber (evt) {
      const text = evt.clipboardData.getData('text')
      const validator = this.isStringNumeric(text)
      console.log(validator)
      if (!validator.valid) {
        this.phoneUsername = text
        return
      }
      let filter = text.replace(/[^\d]/g, '')
      if (filter.length > this.maxlength) {
        filter = filter.substring(0, this.maxlength)
      }
      this.phoneUsername = filter
    },
    muteCall(call, muteSelectedCall = null) {
      this.setDisableMuteButton(true)
      const s = getSessionById(call.id)
      if (s) {
        const _call = call.mute
        this.$store.commit('sip/muteCall', {
          id: s.id,
          mute: muteSelectedCall === null ? !_call : muteSelectedCall
        })
        callMute(muteSelectedCall === null ? !_call : muteSelectedCall, s)
      }
      this.setDisableMuteButton(false)
    },

    async hangup(call) {
      const s = getSessionById(call.id)
      if (!s) return
      const res = await callHangup(s)
      if (isOutboundCall(s)) {
        this.phoneUsername = ''
      }
      this.setCallKeypad(false)
      if (res !== 'fail' && call.type === 'outbound') {
        this.setPhoneOnCall('')
      }
    },

    async toCall() {
      this.setDisableHoldButton(true)
      this.setIsCallOnHold(true)
      this.setNotOpenCall(false)
      call(this.phone)
      beep(true)
      // this.phoneUsername = ''
    },
    async hold(holdSelectedCall = null) {
      this.setDisableHoldButton(true)
      const s = getSessionById(this.getOutboundCall.id)
      if (s) {
        const _hold = this.getOutboundCall.hold
        const res = await callHold(holdSelectedCall === null ? !_hold : holdSelectedCall, s)
        if (res === 'success') {
          this.$store.commit('sip/holdCall', {
            id: s.id,
            hold: holdSelectedCall === null ? !_hold : holdSelectedCall
          })
        }
      }
      this.setDisableHoldButton(false)
    },
    async getProfile () {
      try {
        const data = await this.getUserProfile()
        this.profile = data
      } catch (err) {
        throw new Error(err)
      }
    },
    getContactName (number) {
      const contact = this.contacts.find(e => +e.phone_number === +number)
      return contact ? ((contact.first_name || '') + ' ' + (contact.last_name || '')) : null
    }
  },
  async created() {
    await this.getProfile()
    await this.getContacts()
  },
  watch: {
    calls() {
      this.displayCallOption()
    },
    '$store.state.sip.phoneNumber' (pn) {
      if (this.$store.state.sip.calls.length === 0) {
        this.setPhone(pn)
        this.phoneUsername = pn
        pn && this.toCall()
      }
    },
    phoneUsername (value, oldValue) {
      const validator = this.isStringNumeric(value)
      if (!validator.valid && (value.length < oldValue.length)) {
        this.setPhone('')
      }
    }
  }
}
</script>

<style lang="scss">
.call-block-wrapper {
  width: 362px;
  height: calc(100vh - 72px)!important;
  position: relative;
  background-color: white;
  overflow-y: auto;
  border-left: 1px solid rgb(213, 213, 213);
  z-index: 0;
}
@media (max-width: 1010px) {
  .call-block-wrapper {
    width: 0;
    height: calc(100vh - 72px)!important;
    position: relative;
    background-color: white;
    overflow-y: auto;
    border-left: 1px solid rgb(213, 213, 213);
    z-index: 0;
  }
}
.showKeyword {
  line-height: 20px;
  letter-spacing: -0.02em;
  font-size: 14px;
  //padding: 10px 0 !important;
  //margin-bottom: 10px !important;
}

.enterPhone {
  border-top: 1px solid;
  border-radius: inherit;
  border-color: rgba(0, 0, 0, 0.12);
  margin-top: 5px !important;

  .v-input__slot {
    box-shadow: none !important;
  }

  fieldset {
    border: none !important;
    outline: none;
  }
}

.rightCallNumber {
  .v-text-field__details {
    display: none;
  }
}

.callButtonPosition {
  position: absolute;
  right: 11px;
  background: white;
}

.callSectionButton {
  height: inherit !important;
  background: transparent !important;
  border: none;
  box-shadow: none;
}

.callSectionButton:hover:active,
.callSectionButton:hover::before,
.callSectionButton:hover:focus {
  opacity: 0 !important;
}

//.callSectionButton:hover {
//  background: transparent !important;
//}

.calculateNumberButton {
  color: #a0a0a0 !important;
}

.disableHoldButton {
  cursor: no-drop;
  pointer-events: none;
}

.enableHoldButton {
  cursor: pointer;
}

.grey-33 {
  color: #333333;
}
.calculatePhoneOnCall:active {
  .v-btn__content {
    display: flex;
    flex-direction: column-reverse;

    > span {
      color: #5657af;
    }
  }
}
.calculatePhoneOnCall:hover {
  .v-btn__content {
    display: flex;
    flex-direction: column-reverse;

    > span {
      color: #5657af;
    }
  }
}
.calculatePhoneOnCall {
  .v-btn__content {
    display: flex;
    flex-direction: column-reverse;
    transition-duration: unset!important;

    > span {
      color: #333333;
    }
  }
}
.call-icon {
  background: #F5F5F5;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  cursor: none
}
.incoming-call-popup {
  background: white;
  position: absolute;
  top: 12px;
  width: 85%;
  left: 26px;
  z-index: 10000;
  padding: 16px;
  border-radius: 12px !important;
}
.incoming-call-popup-action-btn {
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 30px !important;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.incoming-call-popup-action-btn-answered {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.incoming-call-popup-action-btn:hover {
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 30px !important;
  padding: 5px 12px;
  color: white;
  background: rgba(117, 119, 125, 0.52);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;
}
.incoming-call-popup-inc {
  font-size: smaller;
  color: #7a7e95;
}
.contacts-wrapper {
  width: 100%;
  max-height: 200px;
  height: 100%;
  overflow-y: auto;
  border-bottom: 1px solid #E0E0E0;
  background-color: white;
}
.contact-block {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0 8px 0;
}
.thin-scroll::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}
.thin-scroll::-webkit-scrollbar-track {
  background-color: #E3EDEE !important;
  border-radius: 1px !important;
}
.thin-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(76, 77, 74, 0.6) !important;
  border-radius: 1px !important;
}
.dialpad-block {
  background-color: white;
  height: 100%!important;
}
@media (max-width: 1010px) {
  .call-block-out {
    height: calc(100vh - 72px) !important;
    overflow-y: auto;
  }
  .less1010 {
    margin-bottom: 100px;
  }
}
</style>
