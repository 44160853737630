import axios from 'src/_services/axios-configs'
import Vue from 'vue'

const state = {
  callItem: null,
  voicemailItem: null,
  messageItem: null,
  voicemails: [],
  calls: [],
  unreadCount: {
    voicemails: 0
  },
  totalVoicemails: 0
}

const getters = {
  callItem: s => s.callItem,
  voicemailItem: s => s.voicemailItem,
  messageItem: s => s.messageItem
}

const mutations = {
  setCallItem (state, callItem) {
    state.callItem = callItem
  },
  setVoicemailItem (state, voicemailItem) {
    state.voicemailItem = voicemailItem
  },
  setMessageItem (state, messageItem) {
    state.messageItem = messageItem
  },
  addVoice (state, voice) {
    Vue.set(state, 'voicemails', [...state.voicemails, voice])
  },
  addCall (stat, payload) {
    const indexCall = state.calls.findIndex(call => call.call_uuid === payload.call_uuid)
    if (indexCall !== -1) {
      Vue.set(state.calls, indexCall, payload)
    } else {
      Vue.set(state, 'calls', [...state.calls, payload])
    }
  },
  removeCalls (state) {
    state.calls = []
  },
  updateUnreadCount (state, payload) {
    if (payload.action === 'add') {
      state.unreadCount[payload.type]++
    }
    if (payload.action === 'minus') {
      if (state.unreadCount[payload.type] === 0) return
      state.unreadCount[payload.type]--
    }
    if (payload.action === 'update') {
      state.unreadCount[payload.type] = payload.count
    }
  }
}

const actions = {
  async getCalls (_, { dir, params }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/logging/call/list/${dir}`, { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async getVoicemailUnreadCount ({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/logging/voicemail/unread/count')
        .then((res) => {
          const payload = {
            count: res.data.unread_count,
            type: 'voicemails',
            action: 'update'
          }
          commit('updateUnreadCount', payload)
          resolve(payload)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  markVoicemailAsRead({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/logging/voicemail/in/read?uuid=${uuid}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  deleteVoicemail({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/logging/voicemail/${uuid}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  addVoice ({ commit }, voice) {
    commit('addVoice', voice)
    commit('updateUnreadCount', { type: 'voicemails', action: 'add' })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
