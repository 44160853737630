const notificationRequestPermission = (func) => {
  Notification.requestPermission(func)
}
const notificationInit = (func = () => {}) => {
  if (!('Notification' in window)) {
    console.error('This browser does not support desktop notifications.')
    return
  }
  const permission = Notification.permission
  if (permission === 'granted') {
    func()
  } else if (permission === 'default') {
    notificationRequestPermission(() => {
      func()
    })
    return 'default'
  } else {
    return 'denied'
  }
}
const showNotification = (payload) => {
  if (document.visibilityState === 'visible') {
    return
  }
  if (!('Notification' in window)) {
    console.error('This browser does not support desktop notifications.')
    return
  }
  const permission = Notification.permission
  if (permission === 'denied') {
    return
  }
  if (permission === 'default') {
    notificationInit()
    return
  }
  const { title, body, icon } = payload
  const notification = new Notification(title, {
    icon,
    body
  })
  notification.onclick = () => {
    notification.close()
    window.parent.focus()
    if (payload.linkFunction) {
      payload.linkFunction()
    }
  }
}

export {
  notificationRequestPermission,
  showNotification,
  notificationInit
}
