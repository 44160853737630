import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        info: '#2f80ed',
        primary: '#5758B0',
        dark: '#333', // #E53935
        muted: '#828282',
        lightMuted: '#BDBDBD',
        success: '#27AE60',
        danger: '#EB5757'
      }
    }
  }
})
