import Vue from 'vue'
import axios from '../../_services/axios-configs'
const state = {
  messageItem: null,
  conversationUuid: null,
  listMessages: [],
  messages: {},
  total: 0,
  newContactCounter: 0,
  unreadCount: 0
}

const getters = {
  messageItem: s => s.messageItem,
  conversationUuid: s => s.messageItem?.conversationUuid,
  messages: s => s.messages
}

const mutations = {
  setMessageItem (state, messageItem) {
    state.messageItem = messageItem
  },
  setTotal (state, total) {
    state.total = total
  },
  updateListMessages (state, payload) {
    state.listMessages = payload
  },
  addListMessages (state, payload) {
    state.listMessages = [...state.listMessages, ...payload]
  },
  setUnreadCount (state, payload) {
    state.unreadCount = payload
  },
  updateUnreadCount (state, payload) {
    if (payload.action === 'add') {
      state.unreadCount++
    }
    if (payload.action === 'minus') {
      if (state.unreadCount === 0) return
      state.unreadCount--
    }
  },
  updateListMessage (state, payload) {
    const num = payload.number
    const messageBlockIndex = state.listMessages.findIndex(item => item.num === num)
    if (messageBlockIndex !== -1) {
      if (payload.message.direction === 'in' && (state.listMessages[messageBlockIndex].is_read && state.listMessages[messageBlockIndex].direction === 'in')) {
        state.unreadCount++
      }
      Vue.set(state.listMessages, messageBlockIndex, {
        ...state.listMessages[messageBlockIndex],
        ...payload.message,
        text: payload.message.text,
        direction: payload.message.direction,
        is_read: payload.message.is_read
      })
      return
    }
    Vue.set(state, 'listMessages', [payload.message, ...state.listMessages])
    state.newContactCounter++
    state.unreadCount++
  },
  updateWssMessages (state, payload) {
    if (state.messages[payload.number]) {
      const isExist = state.messages[payload.number].findIndex(item => item.sms_uuid === payload.message.sms_uuid)
      if (isExist !== -1) {
        Vue.set(state.messages[payload.number], isExist, payload.message)
      } else {
        Vue.set(state.messages, payload.number, [...state.messages[payload.number], payload.message])
      }
    } else {
      Vue.set(state.messages, payload.number, [])
      Vue.set(state.messages, payload.number, [payload.message])
    }
  },
  clearWsMessages (state, number) {
    if (!state.messages[number]) return
    Vue.set(state.messages, number, [])
  }
}

const actions = {
  updateWssMessages ({ commit }, payload) {
    commit('updateWssMessages', payload)
    commit('updateListMessage', payload)
  },
  markSmsAsRead({ commit }, conversationNumber) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/logging/sms/read?conversation_number=${conversationNumber}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
